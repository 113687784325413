import {getComparator, stableSort} from './tableHelpers';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault('America/New_York');

/**
 * @typedef {Object} SearchValue
 * @property {Array} additionalFilters
 * @property {Array} filters
 * @property {Array} boolFilters
 */

/**
 * Group Object type definition
 * @typedef {Object} Group
 * @property {String} orderBy
 * @property {String} groupTitle
 * @property {String} order
 * @property {Number} rowsPerPage
 * @property {SearchValue} searchValue
 * @property {Array} dataTypes
 * @property {Array} data
 * @property {String} filteredData
 * @property {boolean} showBearBullFilter
 * @property {Number} page
 * @property {null | string} activeFilter
 * @property {String} group
 * @property {Array} orderTypes
 */
const defaultGroupObj = {
  orderBy: '',
  groupTitle: '',
  order: 'asc',
  rowsPerPage: 25,
  searchValue: {
    additionalFilters: [],
    filters: [],
    boolFilters: [],
    bearBullFilters: [],
  },
  dataTypes: [],
  data: [],
  filteredData: [],
  showBearBullFilter: false,
  page: 0,
  activeFilter: null,
  group: '',
  orderTypes: [],
  type: '',
  selectedDate: null,
  showDateSelector: false,
  limit: null,
  isCurrentDate: true,
  chartAllowed: false,
};

/**
 * Formats the data type to be used in table header.
 * @param {Object} dataType - Object containing all data types and their formats.
 * @param {*} dt - Data type to be formatted.
 * @returns {Object} - Data type property with properties for MUI TableHead.
 */
export const formatDataTypes = (dataType, dt) => {
  if (dt.key === 'Symbol') {
    return {...dt, disablePadding: true, numeric: false};
  }

  if (dataType?.display === 'percentage' && dt.key === 'HO_Chg') {
    return {
      ...dt,
      disablePadding: false,
      numeric: dt.type === 'number',
      key: 'HO_Chg%',
      title: 'HO. Change (%)',
    };
  }
  if (dt.display === 'percentage' && dt.key !== 'HO_Chg') {
    return {
      ...dt,
      disablePadding: false,
      numeric: dt.type === 'number',
    };
  }
  if (dt.display === 'bullish-bearish') {
    return {
      ...dt,
    };
  }
  return {
    ...dt,
    disablePadding: false,
    numeric: dt.type === 'number',
  };
};

/**
 * Formats the filters based on filter type
 * @param {Object} filt - filter object from websocket
 * @returns {Object} - formatted filter
 */
export const formatFilterableTypes = (filt) => {
  if (filt?.filterable?.type === 'range') {
    return {...filt, value: {min: 0, max: 0}};
  }
  if (filt?.type === 'boolean') {
    const filtClone = {
      ...filt,
      filterable: {
        ...filt.filterable,
        label: filt.title,
        option: false,
      },
    };
    return {...filtClone, value: false};
  }
  if (filt?.type === 'string' && filt?.filterable?.type !== 'hardcode' && filt?.filterable?.type !== 'multiselect') {
    return {...filt, value: ''};
  }
  if (filt?.type === 'array') {
    return {...filt, value: []};
  }
  if (filt?.type === 'hardcode') {
    return {...filt, value: ['Bullish', 'Bearish']};
  }
  if (filt?.type === 'hardcode') {
    return {...filt, value: ['Bullish', 'Bearish']};
  }
  if (filt?.type === 'string' && filt?.filterable?.type === 'multiselect') {
    return {...filt, value: []};
  }
  if (filt?.type === 'toggle') {
    return {...filt, value: false};
  }
  return filt;
};

/**
 * Adds the label name to the filter object to display in the UI
 * @param {Object} item - item to be formatted
 * @param {Object} dataType - data type to be formatted
 * @returns {Object} - formatted item
 */
export const formatBoolFilters = (item, dataType) => {
  const fieldName = item.fields.map((field) => {
    const labelName = dataType?.find((dt) => dt.key === field);
    return {
      field,
      option: false,
      label: labelName.title,
    };
  });
  return {...item, fields: fieldName};
};

/**
 * Structures the search value for group
 * @param {Group} groupObj
 * @param {Array} filtersWithValue
 * @param {Array} withFieldLabel
 * @returns {SearchValue} - SearchValue based on group settings
 */
export const createSearchValueObj = (groupObj, filtersWithValue, withFieldLabel) => {
  const allFilterableItems = [...filtersWithValue, ...withFieldLabel];
  const checkboxFilts = allFilterableItems
    .filter((item) => item?.type === 'boolean')
    .map((field) => ({...field, ...field.filterable}));
  const bearBullFilts = allFilterableItems
    .filter((item) => item?.filterable?.type === 'hardcode')
    .map((field) => ({...field, ...field.filterable}));
  return {
    ...groupObj.searchValue,
    filters: allFilterableItems, // .filter((item) => item?.type !== 'boolean'),
    boolFilters: checkboxFilts,
    bearBullFilters: bearBullFilts,
  };
};

/**
 * Takes date and returns string with separated time value
 *
 * @param {date} t - date to format
 * @param {Array} a - Array with format of returned string
 * @param {String} s - separator (default: '-')
 * @returns {String} - formatted date (default: 'YYYY-MM-DD')
 */
export const join = (t, a, s) => {
  const format = (m) => {
    const f = new Intl.DateTimeFormat('en', m);
    return f.format(t);
  };
  const separator = s || '-';
  return a.map(format).join(separator);
};

/**
 * Take URL params, format into a list to join to websocket
 *
 * @returns {Array} - Array of objects with the following structure:
 * @property {String} group - Group name
 * @property {String} type - Type of group (stateview, tickview)
 * @property {String} date - Requested date
 * @property {boolean} joined - Whether the group is joined or not (false by default)
 */
export const setupGroups = (id = null, scannersData = null) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const groupId = id ?? params.id;
  let group = params.id;
  let paramType = params.type;
  let dateSelector;
  let secondaryView;
  let title;
  let locked;
  let upcoming;
  let hideTab;

  if (scannersData) {
    const scanner = scannersData.find((s) => s.slug === groupId);
    if (scanner && scanner.groups) {
      group = scanner.groups.map((g) => g.group).join(',');
      paramType = scanner.groups.map((g) => g.type).join(',');
      secondaryView = scanner.groups.map((g) => g.secondaryView ?? false).join(',');
      locked = scanner.groups.map((g) => g.locked ?? false).join(',');
      upcoming = scanner.groups.map((g) => g.upcoming ?? false).join(',');
      title = scanner.groups.map((g) => g.title ?? false).join(',');
      dateSelector = scanner.dateSelector;
      hideTab = scanner.groups.map((g) => g?.hideTab ?? false).join(',');
    }
  }

  const {date: dateParam, showDate} = params;
  const getScannerDiv = document.getElementById('scan_scanner');

  const fromDataAttribute = getScannerDiv?.getAttribute('data-scanner-group') ?? null;

  const fromDataScannerType = getScannerDiv?.getAttribute('data-scanner-type') ?? null;

  const fromDataScannerDate = getScannerDiv?.getAttribute('data-scanner-date') ?? null;

  const showDateSelector = getScannerDiv?.getAttribute('data-scanner-show-date-selector') ?? null;

  const rbGroup = fromDataAttribute ?? group ?? null;
  const rbType = fromDataScannerType ?? paramType ?? null;
  const rbDate = fromDataScannerDate ?? dateParam ?? null;

  const groupArr = rbGroup?.split(',');
  const groupToArr = Array.isArray(groupArr) ? groupArr : groupArr ? [groupArr] : [];

  const typeArr = rbType?.split(',');
  const typeToArr = Array.isArray(typeArr) ? typeArr : typeArr ? [typeArr] : [];

  const secondaryViewArr = secondaryView?.split(',');
  const secondaryViewArrToArr = Array.isArray(secondaryViewArr)
    ? secondaryViewArr
    : secondaryViewArr
    ? [secondaryViewArr]
    : [];

  const lockedArr = locked?.split(',');
  const lockedArrToArr = Array.isArray(lockedArr) ? lockedArr : lockedArr ? [lockedArr] : [];

  const upcomingArr = upcoming?.split(',');
  const upcomingArrToArr = Array.isArray(upcomingArr) ? upcomingArr : upcomingArr ? [upcomingArr] : [];

  const titleArr = title?.split(',');
  const titleArrToArr = Array.isArray(titleArr) ? titleArr : titleArr ? [titleArr] : [];

  const hideTabArr = hideTab?.split(',');
  const hideTabArrToArr = Array.isArray(hideTabArr) ? hideTabArr : hideTabArr ? [hideTabArr] : [];

  const a = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
  const today = join(new Date(), a, '-');

  const groupWithType =
    groupToArr?.map((gr, i) => ({
      group: gr,
      type: typeToArr[i] ?? 'tickalert',
      date: rbDate ?? today,
      showDateSelector: showDateSelector ?? showDate ?? dateSelector ?? false,
      joined: false,
      sentJoin: false,
      isCurrentDate: rbDate ? rbDate === today : true,
      secondaryView: JSON.parse(secondaryViewArrToArr[i] ?? 'false'),
      locked: JSON.parse(lockedArrToArr[i] ?? 'false'),
      upcoming: JSON.parse(upcomingArrToArr[i] ?? 'false'),
      title: titleArrToArr[i] ?? null,
      hideTab: JSON.parse(hideTabArrToArr[i] ?? 'false'),
    })) ?? [];

  return groupWithType;
};

export const getTimeKey = (groupObj) => {
  const keys = Object.keys(groupObj);
  return keys.find((key) => groupObj[key].includes('AM') || groupObj[key].includes('PM'));
};

/**
 * Find timestamp and a number value of row in the table.
 * @param {Group} obj
 * @param {Array} dataTypes
 * @returns {String | Number | null} - timestamp of row or null if not found
 */
export const getObjectKeyValueTime = (obj, dataTypes) => {
  const timeValue = dataTypes.find((dt) => dt?.convertTo?.includes('time')) ?? getTimeKey(obj);
  const numberValue =
    dataTypes.find((dt) => dt?.type?.includes('number') && dt?.key?.includes('Count')) ??
    dataTypes.find((dt) => dt?.type?.includes('number'));
  if (timeValue && numberValue && numberValue?.key && obj[numberValue?.key]?.value) {
    return `${obj[timeValue?.key ?? timeValue]?.value}-${obj[numberValue.key]?.value}`.split(' ').join('');
  }
  if (timeValue && !numberValue) {
    return obj[timeValue?.key ?? timeValue]?.value;
  }
  return null;
};

/**
 * Parses numbers and boolean columns to be displayed in the table.
 * @param {Array} data - The data to be sorted and formatted
 * @param {Array} order - The order to be sorted by
 * @param {String} type - Group type (tickalert, stateview)
 * @returns {Object} - The sorted and formatted data with Symbol as key
 */
export const formatDataForTable = (data, order, type = 'tickalert', isUpdate = false, group = null) =>
  data
    .filter((item) => item?.Symbol?.value?.length)
    .map((item) => {
      const itemKeys = Object.keys(item);
      let newItem = item;
      const sort = order ?? [];
      itemKeys.forEach((key) => {
        if (sort.find((dataType) => dataType.key === key)) {
          if (sort.type === 'number') {
            newItem[key] = parseFloat(item[key].value);
          }
        }
      });
      const symbolValue =
        item?.Symbol?.value?.charAt(0) === 'e' ? item?.Symbol?.value.substring(1) : item?.Symbol?.value;
      const getNewRowValue = group && symbolValue && group?.data?.[symbolValue] ? group.data[symbolValue] : null;

      newItem = {
        ...item,
        i60minSqz: item?.i60minSqz?.value ?? false,
        DailySqz: item?.DailySqz?.value ?? false,
        // eslint-disable-next-line camelcase
        HOS_Label: item?.HOS_Label?.value ?? null,
        update: isUpdate ? true : item?.update ?? false,
        keyId: type === 'tickalert' ? `${symbolValue}-${getObjectKeyValueTime(item, order)}` : item.Symbol?.value,
        Symbol: {
          ...(item?.Symbol ?? {}),
          value: symbolValue,
        },
      };

      if (isUpdate && getNewRowValue) {
        const numberDataTypes = group.dataTypes.filter((dt) => dt.type === 'number');
        numberDataTypes.forEach((dt) => {
          const {key} = dt;
          if (getNewRowValue[key]) {
            newItem[`Prev${key}`] = getNewRowValue[key];
          } else {
            newItem[`Prev${key}`] = null;
          }
        });
      }
      return newItem;
    })
    .reduce((ac, a) => {
      const symbolValue = a?.Symbol?.value?.charAt(0) === 'e' ? a?.Symbol?.value.substring(1) : a?.Symbol?.value;
      return {
        ...ac,
        [type === 'tickalert' ? `${symbolValue}-${getObjectKeyValueTime(a, order)}` : symbolValue]: a,
      };
    }, {});

const hasNumbers = (t) => {
  const regex = /\d/g;
  return regex.test(t);
};

export const isValidTick = (tick, currentGrp, allActiveFilters, array, mappedDataTypes) => {
  if (!currentGrp?.activeFilter && !allActiveFilters?.length) return true;

  let meetsCriteria = true;

  if ((allActiveFilters?.length && meetsCriteria) || (!currentGrp.activeFilter && allActiveFilters?.length)) {
    meetsCriteria = allActiveFilters.every((item) => {
      const {filterable, value, type: itemDataType, key, selected} = item;
      const {
        type: filterType,
        hideMatchingValues,
        value: filterBy,
      } = filterable ?? {type: null, hideMatchingValues: false, value: null};

      const {value: tickValue} = tick[key] ?? {value: null};
      const tValue = tickValue ?? tick[key];
      if (filterType === 'range' && (itemDataType === 'number' || itemDataType === 'money')) {
        const minToNum = value?.min ? parseFloat(value?.min) : null;
        const maxToNum = value?.max ? parseFloat(value?.max) : null;

        if (minToNum && maxToNum) {
          return tValue >= minToNum && tValue <= maxToNum;
        }

        if (!minToNum && maxToNum) {
          return tValue <= maxToNum;
        }

        if (minToNum && !maxToNum) {
          return tValue >= minToNum;
        }
      }
      if (filterType === 'search' && itemDataType === 'string') {
        return tValue?.toLowerCase().includes(value?.toLowerCase());
      }

      if (filterType === 'boolean') {
        return selected.length === 0 || selected.includes(tValue);
      }

      if (filterType === 'hardcode') {
        return selected.includes(tValue?.toLowerCase());
      }

      if (itemDataType === 'array') {
        if (!tValue) return false;
        return value.every((tag) => tValue.includes(tag));
      }

      if (filterType === 'toggle') {
        // if (itemDataType === 'multiple-values') {
        //   if (!item.value) {
        //     return true;
        //   }
        //   const {priorityColumn, filterByOnlySymbol} = item.filterable.filterBy;
        //   const dataType = mappedDataTypes[priorityColumn];
        //   const today = dayjs().format('DD/MM/YYYY');
        //   const fullSymbol = tick.Symbol.value;
        //   let sym = fullSymbol;
        //   let format = 'DD/MM/YYYY hh:mm:ss A';
        //   let dataValue = tick[priorityColumn]?.value;

        //   if (tick[priorityColumn]?.withMSTimeValue) {
        //     format = 'DD/MM/YYYY hh:mm:ss:SSS A';
        //     dataValue = tick[priorityColumn]?.withMSTimeValue;
        //   }
        //   if (!dataValue) {
        //     return true;
        //   }
        //   if (dataType?.convertFrom === 'timestamp-after-midnight' || dataType?.convertTo === 'time') {
        //     dataValue = dayjs(`${today} ${dataValue}`, format);
        //   }
        //   if (hasNumbers(sym) && sym) {
        //     [sym] = sym.split(/[0-9]/);
        //   }
        //   const multipleExists = array.filter((d) => {
        //     let s = d.Symbol.value;
        //     if (hasNumbers(s) && s) {
        //       [s] = s.split(/[0-9]/);
        //     }
        //     return s === sym;
        //   });
        //   if (!multipleExists.length || multipleExists.length === 1) {
        //     return true;
        //   }
        //   const filter = multipleExists.every((d, i) => {
        //     const fullDSymbol = d.Symbol.value;
        //     let s = fullDSymbol;
        //     if (
        //       (dataType?.convertFrom === 'timestamp-after-midnight' || dataType?.convertTo === 'time') &&
        //       !filterByOnlySymbol
        //     ) {
        //       const {value: timeValue, withMSTimeValue} = d[priorityColumn];
        //       if (!timeValue) {
        //         return true;
        //       }
        //       const dateValue = dayjs(`${today} ${withMSTimeValue ?? timeValue}`, format);
        //       if (dateValue.isSame(dataValue)) {
        //         return fullDSymbol === fullSymbol;
        //       }
        //       if (dateValue.isBefore(dataValue)) {
        //         return false;
        //       }
        //       return true;
        //     }
        //     if (hasNumbers(s) && sym) {
        //       [s] = s.split(/[0-9]/);
        //     }
        //     if (s === sym) {
        //       return false;
        //     }
        //     return true;
        //   });
        //   console.log('tick', tick, filter);

        //   return filter;
        // }
        let filteringBy = filterBy;
        let tickerValue = tValue;

        if (itemDataType === 'boolean') {
          filteringBy = filterBy;
          tickerValue = tValue === 'true' || tValue === true;
        } else {
          if (!value) return true;
          if (itemDataType === 'number') {
            filteringBy = filterBy.map((v) => parseFloat(v));
            tickerValue = parseFloat(tValue);
          }
        }

        const foundValue = filteringBy.find((v) => v === tickerValue);
        if (hideMatchingValues) {
          return !foundValue;
        }
        return foundValue;
      }

      if (filterType === 'multiselect' && itemDataType === 'string') {
        if (!tValue) return false;
        if (key === 'Symbol' && hasNumbers(tValue)) {
          const ticker = tValue.split(/[0-9]/)[0];
          return value.includes(ticker);
        }
        return value.includes(tValue);
      }
      return true;
    });
  }
  return meetsCriteria;
};

/**
 * Update filteredData value based on filters
 * @param {Group} group
 * @returns {Array} - group.filteredData
 */
export const updateFilteredData = (group) => {
  if (!group) return [];
  const currentGrp = group;
  const currentData = currentGrp?.data;
  const searchV = currentGrp?.searchValue;
  if (!currentData || !Object.keys(currentData || {})?.length) {
    currentGrp.filteredData = currentData;
    return currentGrp.filteredData;
  }

  // Filters
  const allActiveFilters = searchV?.filters?.filter((item) => {
    const {filterable, value, type, selected} = item;
    if (filterable?.type === 'range') {
      return value?.min || value?.max;
    }
    if (filterable?.type === 'search' && type === 'string') {
      return value && value !== '';
    }
    if (type === 'array') {
      return value?.length;
    }
    if (type === 'boolean') {
      return selected?.length > 0;
    }
    if (filterable?.type === 'hardcode') {
      return selected?.length > 0;
    }
    if (filterable?.type === 'toggle') {
      return value !== undefined && value !== null;
    }
    if (filterable?.type === 'multiselect' && type === 'string') {
      return value?.length;
    }
    return false;
  });

  if (!allActiveFilters?.length && !currentGrp?.activeFilter) {
    currentGrp.filteredData = {...(currentData || {})};
    return currentGrp.filteredData;
  }
  const filterData = {...currentData};
  const keys = Object.keys(filterData);
  const dataToArray = keys.reduce((acc, key) => [...(acc || []), filterData[key]], []);
  const mappedDataTypes = currentGrp?.dataTypes?.reduce((acc, dataType) => {
    const {key} = dataType;
    return {
      ...acc,
      [key]: {
        ...dataType,
      },
    };
  }, {});
  const formattedData = dataToArray.filter((tick, index, dataArr) =>
    isValidTick(tick, currentGrp, allActiveFilters, dataArr, mappedDataTypes),
  );
  const filterType = currentGrp?.dataTypes?.find((type) => type.key === currentGrp?.orderBy) ?? {};
  // const currentSort = stableSort(
  //   formattedData,
  //   getComparator(currentGrp.order, currentGrp.orderBy, currentGrp.dataTypes),
  // );
  const currentSort = stableSort(formattedData, currentGrp?.order, currentGrp?.orderBy, filterType, [], searchV);

  // Save data as Object instead of Array.
  const withHashKey = currentSort.reduce((ac, a) => {
    const symbolValue = a?.Symbol?.value?.charAt(0) === 'e' ? a?.Symbol?.value.substring(1) : a?.Symbol?.value;
    const key =
      currentGrp.type === 'tickalert'
        ? `${symbolValue}-${getObjectKeyValueTime(a, currentGrp.dataTypes)}`
        : symbolValue;
    return {...ac, [key]: a};
  }, {});
  currentGrp.filteredData = withHashKey;
  return currentGrp.filteredData;
};

export const resetRange = (group, key) => {
  if (!group) return {};
  const groupToUpdate = {...group};
  groupToUpdate.page = 0;
  const searchValueCopy = {...groupToUpdate.searchValue};
  const filterToUpdate = searchValueCopy.filters.find((filter) => filter.key === key);
  if (filterToUpdate) {
    filterToUpdate.value = {min: '', max: ''};
  }
  groupToUpdate.searchValue = searchValueCopy;
  groupToUpdate.filteredData = updateFilteredData(groupToUpdate);
  return groupToUpdate;
};

/**
 * Updates the group object with the new search/filter values
 * @param {String | number | boolean} updatedValue
 * @param {String} key
 * @param {String | null} minMax
 * @param {Group} group
 * @param {Field} field
 * @returns {Group} Group with updated value
 */
export const updateFilters = (updatedValue, key, minMax, group) => {
  if (!group) return {};
  const groupToUpdate = {...group};
  groupToUpdate.page = 0;
  const prevSearchValue = groupToUpdate.searchValue;
  const newSearchValueCopy = {...prevSearchValue};
  const filterToUpdateIndex = newSearchValueCopy.filters.findIndex((filter) => filter.key === key);
  const isBoolFilter = newSearchValueCopy?.boolFilters?.find((filter) => filter.key === key);
  if (filterToUpdateIndex > -1) {
    const filterToUpdate = newSearchValueCopy.filters[filterToUpdateIndex];
    if (minMax) {
      if (minMax === 'min') {
        filterToUpdate.value = {
          ...filterToUpdate.value,
          min: updatedValue,
        };
      }
      if (minMax === 'max') {
        filterToUpdate.value = {
          ...filterToUpdate.value,
          max: updatedValue,
        };
      }
    } else if (filterToUpdate.display === 'bullish-bearish' || filterToUpdate.type === 'boolean') {
      filterToUpdate.selected = filterToUpdate?.selected ? filterToUpdate.selected : [];
      const updatedValueIndex = filterToUpdate?.selected.indexOf(updatedValue);
      if (updatedValueIndex === -1) {
        filterToUpdate.selected = [...filterToUpdate.selected, updatedValue];
      } else {
        filterToUpdate.selected.splice(updatedValueIndex, 1);
      }
    } else {
      const filtersClone = [...newSearchValueCopy.filters];
      filtersClone[filterToUpdateIndex] = {
        ...(filtersClone[filterToUpdateIndex] ?? {}),
        value: filterToUpdate?.filterable?.type === 'multiselect' && updatedValue === null ? [] : updatedValue,
      };
      groupToUpdate.searchValue = {
        ...newSearchValueCopy,
        filters: filtersClone,
      };
      groupToUpdate.filteredData = updateFilteredData(groupToUpdate);
      return groupToUpdate;

      // newSearchValueCopy.filters[filterToUpdateIndex] = newVal;
    }
  } else if (isBoolFilter) {
    isBoolFilter.value = updatedValue;
    isBoolFilter.option = updatedValue;
  }
  groupToUpdate.searchValue = newSearchValueCopy;
  groupToUpdate.filteredData = updateFilteredData(groupToUpdate);
  return groupToUpdate;
};

/**
 * Setup group object and format to work with MUI Table
 *
 * @param {Group} gr - group object
 * @param {String} group - group name
 * @param {Object} settings - scanner settings from websocket
 * @param {Array} data - scanner data
 * @param {Array} reset - if true, reset group data
 * @returns Group - group object
 */
export const setupSettings = (gr, group, settings, data, type, reset, datePicker, showDateSelector, chartAllowed) => {
  // If group object exists in the collection, update it else create new one.
  const groupObj = gr ? (reset ? {...defaultGroupObj, group} : gr) : {...defaultGroupObj};

  if (settings) {
    const {groupInfo, dataType} = settings;
    const {
      additionalFilters,
      defaultSort,
      defaultSortOrder,
      title,
      limit,
      order: itemOrder,
      timeObject,
      displayFilterForUniqueSymbols,
    } = groupInfo;

    const acc = [{year: 'numeric'}, {month: '2-digit'}, {day: '2-digit'}];
    const today = join(new Date(), acc, '-');

    groupObj.group = group;
    groupObj.type = type;
    groupObj.selectedDate = datePicker;

    // set group order
    groupObj.orderTypes = itemOrder;

    // Set default sorting column
    groupObj.orderBy = defaultSort ?? [];

    groupObj.order = defaultSortOrder ?? 'asc';

    // Set Limit
    groupObj.limit = limit ?? false;

    groupObj.isCurrentDate = showDateSelector && datePicker ? datePicker === today : true;

    groupObj.chartAllowed = chartAllowed;

    // Set page title
    groupObj.groupTitle = title ?? group?.split(/(?=[A-Z])/).join(' ');

    const orderedDataTypes =
      dataType && dataType?.length ? [...dataType].sort((a, b) => itemOrder[a.key] - itemOrder[b.key]) : [];

    // Format dataTypes with materialUI settings
    groupObj.dataTypes =
      orderedDataTypes && orderedDataTypes?.length ? orderedDataTypes?.map((dt) => formatDataTypes(dataType, dt)) : [];

    // Format table data with limit.

    if (limit && data) {
      groupObj.data =
        type === 'Report' || type === 'report'
          ? {}
          : formatDataForTable(
              data.length > limit ? [...data].reverse().slice(0, limit).reverse() : data,
              orderedDataTypes,
              type,
            );
    } else {
      groupObj.data =
        type === 'Report' || type === 'report' ? {} : data ? formatDataForTable(data, orderedDataTypes, type) : {};
    }

    // Format dataTypes that are filterable for searchValue.
    const filtersWithValue =
      dataType && dataType?.length
        ? dataType
            ?.filter((dt) => Object.keys(dt || {}).includes('filterable'))
            ?.map((filt) => {
              if (filt?.filterable?.type === 'hardcode' && filt?.display === 'bullish-bearish') {
                // groupObj.searchValue.bearBullFilters = [];
                groupObj.showBearBullFilter = true;
              }
              return formatFilterableTypes(filt);
            })
        : [];

    // Add additional filters for searchValue.
    const withFieldLabel =
      additionalFilters
        ?.filter((item) => item?.type === 'joined-boolean')
        ?.map((item) => formatBoolFilters(item, dataType)) ?? [];

    // Set search value
    groupObj.searchValue = createSearchValueObj(groupObj, filtersWithValue, withFieldLabel);

    // Add symbol filter for unique symbols for tickalerts
    // if (groupObj?.type === 'tickalert' || displayFilterForUniqueSymbols) {
    //   const symbolFilter = {
    //     type: 'multiple-values',
    //     filterable: {
    //       title: 'Filter Repeat Symbols',
    //       key: 'Symbol',
    //       type: 'toggle',
    //       filterBy: {
    //         priorityColumn: timeObject,
    //         filterByOnlySymbol: false,
    //       },
    //     },
    //     value: false,
    //   };
    //   groupObj.searchValue = {
    //     ...groupObj.searchValue,
    //     filters: [...(groupObj.searchValue?.filters ?? []), symbolFilter],
    //   };
    // }
  }
  groupObj.group = group;
  groupObj.filteredData = type === 'Report' || type === 'report' ? {} : updateFilteredData(groupObj, type);
  groupObj.showDateSelector = showDateSelector;
  groupObj.rawData = data;
  return groupObj;
};

/**
 * Resets group object to default values
 * @param {Group} gr - Group to reset
 * @returns Group - Group reset to default object.
 */
export const resetSettings = (gr) => ({...defaultGroupObj, group: gr});

/**
 * For stateview tickers, add a timestamp to the key
 * @param {Array} formattedData
 * @param {Array} dataTypes
 * @returns
 */
export const addHashKey = (formattedData, dataTypes) =>
  formattedData.reduce((ac, a) => {
    const symbolValue = a?.Symbol?.value?.charAt(0) === 'e' ? a?.Symbol?.value.substring(1) : a?.Symbol?.value;
    return {
      ...ac,
      [`${symbolValue}-${getObjectKeyValueTime(a, dataTypes)}`]: a,
    };
  }, {});

export const createStateViewCSS = (settings) => {
  if (!settings || !settings.dataType || !settings.dataType.length) return;
  const {dataType} = settings;
  const numberDataTypes = dataType.filter(
    (dt) => dt.type === 'number' && (dt.highlightChange || !Object.keys(dt).includes('highlightChange')),
  );
  if (!numberDataTypes.length) return;
  const style = document.createElement('style');
  style.type = 'text/css';
  let innerHtmlStyle = '';
  numberDataTypes.forEach((dt) => {
    const {key} = dt;
    const newStyle = `
        .${key}Decrease .${key} .scanner-item span {
          /* background-color: rgba(225, 0, 0 , 0.4); */
          color: rgba(225, 0, 0 , 1) !important;
          transition: all 0.5s ease-in-out;
        }
        .${key}Increase .${key} .scanner-item span {
          color: rgba(0, 128, 0, 1) !important;
          transition: all 0.5s ease-in-out;
        }
  
        .${key}AnimationOut .${key} .scanner-item span {
          color: #70769b !important;
          transition: all 0.5s ease-in-out;
        }
  
        .dark .${key}AnimationOut .${key} .scanner-item span {
          color: #b2b6ce !important;
          transition: all 0.5s ease-in-out;
        }
      `;
    innerHtmlStyle += newStyle;
  });
  style.innerHTML = innerHtmlStyle;
  document.getElementsByTagName('head')[0].appendChild(style);
};
